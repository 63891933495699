export default function useBusinessManager() {
  const supabase = useSupabaseClient();

  const selectCouponRedeems = async (couponId) => {
    try {
      const { data, error } = await supabase
        .from("coupon_redeems")
        .select("*")
        .eq("coupon_id", couponId);
      if (error) {
        throw new Error(`Error al obtener el cupón: ${error.message}`);
      }

      return data;
    } catch (error) {
      console.error(`Error al obtener el cupón:`, error);
      return null;
    }
  };

  const selectCouponRedeemByCode = async (code, userId) => {
    try {
      const { data: business } = await supabase
        .from("businesses")
        .select("business_id")
        .eq("business_admin_profile_id", userId);

      console.log("business_id", business[0].business_id);

      const { data: couponRedeems, error: couponError } = await supabase
        .from("coupon_redeems")
        .select("*, coupons(*, businesses(business_id))")
        .eq("redeem_code", code);

      if (couponError) {
        throw new Error(`Error al obtener los cupones: ${couponError.message}`);
      }

      const filteredCouponRedeems = couponRedeems.filter((coupon) => {
        return (
          coupon.coupons.businesses.business_id === business[0].business_id
        );
      });

      console.log("filteredCouponRedeems", filteredCouponRedeems);
      return filteredCouponRedeems;
    } catch (error) {
      console.error(`Error al obtener el cupón:`, error);
    }
  };

  const insertCouponRedeem = async (dataToInsert) => {
    try {
      const { data, error } = await supabase
        .from("coupon_redeems")
        .insert([dataToInsert])
        .select();
      if (error) {
        throw new Error(`Error al insertar el cupón: ${error.message}`);
      }
      console.log(data);
      return data;
    } catch (error) {
      console.error(`Error al insertar el cupón:`, error);
      return null;
    }
  };

  const updateCouponRedeem = async (couponRedeemId, dataToUpdate) => {
    try {
      const { data, error } = await supabase
        .from("coupon_redeems")
        .update(dataToUpdate)
        .eq("coupon_redeem_id", couponRedeemId)
        .select();
      if (error) {
        throw new Error(`Error al actualizar el cupón: ${error.message}`);
      }

      return data;
    } catch (error) {
      console.error(`Error al actualizar el cupón:`, error);
      return null;
    }
  };

  const selectCouponAndRedeemsByCode = async (code) => {
    try {
      const { data, error } = await supabase
        .from("coupon_redeems")
        .select("*, coupons(*, businesses(*))")
        .eq("redeem_code", code);
      if (error) {
        throw new Error(`Error al obtener el cupón: ${error.message}`);
      }

      return data;
    } catch (error) {
      console.error(`Error al obtener el cupón:`, error);
      return null;
    }
  };

  const selectTotalCouponRedeems = async (couponId) => {
    try {
      const { data, error } = await supabase
        .from("coupon_redeems")
        .select("redeem_count")
        .eq("coupon_id", couponId);
      if (error) {
        throw new Error(`Error al obtener el cupón: ${error.message}`);
      }

      console.log(data);

      const redeemSum = data.reduce(
        (sum, redeem) => sum + Number(redeem.redeem_count),
        0,
      );
      console.log(redeemSum);
      return redeemSum;
    } catch (error) {
      console.error(`Error al obtener el cupón:`, error);
      return null;
    }
  };

  return {
    selectCouponRedeems,
    selectCouponRedeemByCode,
    insertCouponRedeem,
    updateCouponRedeem,
    selectCouponAndRedeemsByCode,
    selectTotalCouponRedeems,
  };
}
